import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { PlatformService } from '../services/platform-service/platform.service';
import { ETileType } from '../interfaces/app.interface';

@Directive({
  standalone: true,
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input() tooltip = '';
  @Input() toolTipStartPage = false;
  @Input() toolTipAppfinder = false;
  @Input() tooltipAppType: ETileType = ETileType.Static;
  @Input() tooltipTemplateRef: TemplateRef<unknown> | undefined;

  private msBeforeShowing = 600;
  private isShowTooltip = false;
  private tooltipComponentRef: ComponentRef<TooltipComponent> | undefined;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private platformService: PlatformService,
  ) {}

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.isShowTooltip = true;
    setTimeout(() => {
      const shouldShowTooltip =
        this.checkToShowToolTipInStartPage() ||
        this.checkToShowToolTipInAppFinder() ||
        (!this.toolTipStartPage &&
          !this.toolTipAppfinder &&
          this.isShowTooltip);

      if (!this.platformService.isMobile() && shouldShowTooltip) {
        this.showTooltip();
      }
    }, this.msBeforeShowing);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isShowTooltip = false;
    this.destroy();
  }

  private showTooltip(): void {
    if (!this.tooltipComponentRef) {
      this.tooltipComponentRef =
        this.viewContainerRef.createComponent(TooltipComponent);
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties() {
    if (!this.tooltipComponentRef) return;
    const { left, right, top } =
      this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipInstance = this.tooltipComponentRef.instance;

    tooltipInstance.tooltip = this.tooltip;
    tooltipInstance.contentRef = this.tooltipTemplateRef;
    tooltipInstance.toolTipStartPage = this.toolTipStartPage;
    tooltipInstance.toolTipAppfinder = this.toolTipAppfinder;
    tooltipInstance.left = (right - left) / 2 + left;

    if (this.toolTipStartPage) {
      tooltipInstance.top = top + (this.tooltip.length < 20 ? 20 : 33);
    } else if (this.toolTipAppfinder) {
      tooltipInstance.top = top + (this.tooltip.length < 20 ? 25 : 50);
    } else {
      tooltipInstance.bottom = window.innerHeight - top;
    }

    this.tooltipComponentRef.location.nativeElement.style.zIndex = '10';
  }

  private checkToShowToolTipInStartPage(): boolean {
    return this.toolTipStartPage && this.isShowTooltip;
  }

  private checkToShowToolTipInAppFinder(): boolean {
    return this.toolTipAppfinder && this.isShowTooltip;
  }

  private destroy(): void {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.destroy();
      this.tooltipComponentRef = undefined;
    }
  }
}
