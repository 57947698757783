<button
  class="a-button -without-icon select-none"
  [ngClass]="{
    'a-button--primary': buttonType === ButtonTypeEnum.PRIMARY,
    'a-button--secondary': buttonType === ButtonTypeEnum.SECONDARY,
    'a-button--tertiary': buttonType === ButtonTypeEnum.TERTIARY,
    'integrator-button': isIntegratorButton,
  }"
  [disabled]="isDisabled"
>
  <span class="a-button__label select-none">{{ buttonLabel }}</span>
</button>
