import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Bookmark } from '../../interfaces/app.interface';
import { UrlService } from '../../services/url-service/url.service';

@Component({
  selector: 'bookmark',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
})
export class BookmarkComponent {
  @Input({ required: true }) bookmark!: Bookmark;

  constructor(private urlService: UrlService) {}

  openAppURL(): void {
    this.urlService.openURL(this.bookmark.bookmarkURL);
  }
}
