import { Injectable } from '@angular/core';
import { NavigationItem } from '../../interfaces/navigation-items';
import { NavigationItemsService } from '../navigation-items-service/navigation-items.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentNavigationItemService {
  public currentNavigationItem!: NavigationItem;

  constructor(private navigationItems: NavigationItemsService) {
    this.currentNavigationItem = this.getCurrentNavigationItemByPathName('/');
  }

  getCurrentNavigationItemByPathName(_pathName: string): NavigationItem {
    const navItem = this.navigationItems
      .getNavigationItems()
      .find((item) => item.path === _pathName);
    return navItem ?? this.navigationItems.getStartPage();
  }
}
