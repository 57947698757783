import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationItem } from '../../../../../interfaces/navigation-items';
import { Router, RouterLink } from '@angular/router';
import { NavigationItemVisibilityService } from '../../../../../services/navigation-service/navigation-item-visibility.service';
import { NavigationItemClickService } from '../../../../../services/navigation-service/navigation-item-click.service';

@Component({
  selector: 'side-navigation-action-item',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './child-navigation-item.component.html',
  styleUrls: ['./child-navigation-item.component.scss'],
})
export class ChildNavigationItem {
  @Input({ required: true }) childNavigationItem!: NavigationItem;

  constructor(
    protected navigationItemClickService: NavigationItemClickService,
    protected navigationItemVisibilityService: NavigationItemVisibilityService,
    protected router: Router,
  ) {}
}
