import { Injectable, signal, WritableSignal } from '@angular/core';
import { FolderInterface } from '../../../core/interfaces/folder.interface';

@Injectable({
  providedIn: 'root',
})
export class FolderUiService {
  private isEditMode: boolean = false;
  private folderBeingRenamed: WritableSignal<FolderInterface | null> =
    signal(null);
  private selectedFolder: WritableSignal<FolderInterface | null> = signal(null);
  public folders: FolderInterface[] = [];

  setSelectedFolder(_folder: FolderInterface) {
    this.selectedFolder.set(_folder);
  }

  getSelectedFolder(): WritableSignal<FolderInterface | null> {
    return this.selectedFolder;
  }

  getIsEditMode() {
    return this.isEditMode;
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    this.setFolderBeingRenamed(null);
  }

  setFolderBeingRenamed(_folder: FolderInterface | null): void {
    this.folderBeingRenamed.set(_folder);
  }

  getFolderBeingRenamed(): WritableSignal<FolderInterface | null> {
    return this.folderBeingRenamed;
  }

  resetEditMode() {
    this.isEditMode = false;
    this.setFolderBeingRenamed(null);
  }
}
