import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationService } from 'src/app/core/services/navigation-service/side-navigation.service';
import { ToggleComponent } from '../../toggle/toggle.component';
import { AppButtonComponent } from '../../app-button/app-button.component';
import { ButtonTypeEnum } from '../../../enums/buttonType.enum';
import { LeaveIntegratorPopupComponent } from '../../../../pages/app-integrator-page/components/pop-ups/leave-integrator-popup/leave-integrator-popup.component';
import { IntegratorToastMessageComponent } from '../../../../pages/app-integrator-page/integrator-toast-message/integrator-toast-message.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { AppSavedSuccessfullyDialogPopupComponent } from '../../../../pages/app-integrator-page/components/pop-ups/app-saved-succesfully-dialog-popup/app-saved-successfully-dialog-popup.component';
import { ApplicationInformationService } from '../../../services/application-information-service/application-information.service';
import { CurrentNavigationItemService } from '../../../services/navigation-service/current-navigation-item.service';
import { PlatformService } from '../../../services/platform-service/platform.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    CommonModule,
    ToggleComponent,
    AppButtonComponent,
    LeaveIntegratorPopupComponent,
    IntegratorToastMessageComponent,
    TooltipDirective,
    AppSavedSuccessfullyDialogPopupComponent,
  ],
})
export class FooterComponent {
  constructor(
    protected sideNavigationService: SideNavigationService,
    protected appInfoService: ApplicationInformationService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    protected platformService: PlatformService,
  ) {}

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected year = new Date().getFullYear();
}
