import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from 'src/app/core/services/user-service/user.service';
import { PlatformService } from 'src/app/core/services/platform-service/platform.service';
import { CurrentNavigationItemService } from 'src/app/core/services/navigation-service/current-navigation-item.service';
import { NavigationItemsService } from '../../../../services/navigation-items-service/navigation-items.service';

@Component({
  selector: 'ide-client',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ide-client.component.html',
  styleUrls: ['./ide-client.component.scss'],
})
export class IdeClientComponent {
  protected isUserFromInternet: boolean =
    this.userService.getUser().userFromInternet ?? false;

  constructor(
    protected userService: UserService,
    protected platformService: PlatformService,
    protected currentNavigationItemService: CurrentNavigationItemService,
    private navigationItems: NavigationItemsService,
  ) {}

  currentNavigationOnStartPage(): boolean {
    return (
      this.currentNavigationItemService.currentNavigationItem.name ===
      this.navigationItems.getStartPage().name
    );
  }
}
