import { Injectable } from '@angular/core';
import { NavigationItem } from '../../interfaces/navigation-items';
import { Router } from '@angular/router';
import { SideNavigationService } from './side-navigation.service';
import { PlatformService } from '../platform-service/platform.service';
import { UrlService } from '../url-service/url.service';
import { ScrollService } from '../scroll-service/scroll.service';
@Injectable({
  providedIn: 'root',
})
export class NavigationItemClickService {
  constructor(
    private router: Router,
    private sideNavigationService: SideNavigationService,
    private platformService: PlatformService,
    private urlService: UrlService,
    private scrollService: ScrollService,
  ) {}

  clickNavigationItem(_navigationItem: NavigationItem): void {
    if (_navigationItem.children?.length) {
      this.toggleChildren(_navigationItem);
      return;
    }

    if (_navigationItem.click) {
      _navigationItem.click();
      return;
    }

    if (_navigationItem.href) {
      if (this.platformService.isMobile()) {
        this.sideNavigationService.closeSideNav();
      }
      setTimeout(() => {
        this.urlService.openURL(_navigationItem.href);
      });
      return;
    }

    if (_navigationItem.routerLink) {
      this.scrollService.forgetPosition(_navigationItem.routerLink);
      this.router.navigate([_navigationItem.routerLink]).then(() => {});
    }
  }

  toggleChildren(_navigationItem: NavigationItem): void {
    if (this.sideNavigationService.isSideNavOpen()) {
      _navigationItem.isOpenGroup = !_navigationItem.isOpenGroup;
    } else {
      this.sideNavigationService.openSideNav();
      _navigationItem.isOpenGroup = true;
    }
  }
}
