import { Injectable } from '@angular/core';
import { Observable, take, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../../interfaces/user.interface';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string = environment.baseUrl;
  authenticationURL: string = this.baseUrl + '/authorize';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location
  ) {
  }

  checkAuthentication(): Observable<User> {
    const locationString = window.location.toString();
    const url = new URL(locationString);

    const code = url.searchParams.get('code');
    if (code !== null) {
      const state = url.searchParams.get('state');
      return this.tokenExchange(code).pipe(
        tap(async () => {
          await this.navigateToState(state);
        })
      );
    } else {
      return this.getLoggedInUser();
    }
  }

  redirectToAzureAd() {
    /*
     * Be Aware, router service isn't initialized in the APP_INITIALIZE step.
     */
    const initialRoute = this.location.path();

    const allowedStateUrlSize = 500;
    let encodedInitialRoute = encodeURIComponent(initialRoute);
    if (encodedInitialRoute.length > allowedStateUrlSize) {
      encodedInitialRoute = encodedInitialRoute.substring(0,allowedStateUrlSize - 1);
    }

    this.http
      .get(
        environment.baseUrl + '/authenticate/loginurl?state=' + encodedInitialRoute,
        { responseType: 'text' }
      )
      .pipe(take(1))
      .subscribe((_loginURL) => {
        window.location.href = _loginURL;
      });
  }

  private getLoggedInUser(): Observable<User> {
    return this.http.get<User>(this.authenticationURL, this.httpOptions);
  }

  private tokenExchange(_authorizationCode: string): Observable<User> {
    return this.http.get<User>(
      this.baseUrl + '/authenticate/' + _authorizationCode,
      this.httpOptions
    );
  }

  private async navigateToState(_state: string | null): Promise<void> {
    if (_state) {
      await this.router.navigateByUrl(_state);
    } else {
      await this.router.navigate(['/']);
    }
  }
}
