import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { SideNavigationService } from '../../../services/navigation-service/side-navigation.service';
import { CurrentNavigationItemService } from '../../../services/navigation-service/current-navigation-item.service';

@Component({
  selector: 'main-container',
  standalone: true,
  imports: [NgClass],
  templateUrl: './main-container.component.html',
  styleUrl: './main-container.component.scss',
})
export class MainContainerComponent {
  constructor(
    protected sideNavigationService: SideNavigationService,
    protected currentNavigationItemService: CurrentNavigationItemService,
  ) {}
}
