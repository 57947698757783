import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';

export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: unknown): void {
    if (!environment.production) {
      console.error(error);
    }
  }
}
