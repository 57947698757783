import { Injectable } from '@angular/core';
import { SearchResults } from '../../interfaces/search-results.interface';
import { AppInterface } from '../../interfaces/app.interface';
import { PlatformService } from '../platform-service/platform.service';
import { SearchCriteria } from './search-criteria.interface';
import { AppTitleCriteria } from './criterias/app-title-criteria';
import { SearchableAppInterface } from './searchable-app.interface';
import { AppNameCriteria } from './criterias/app-name-criteria';
import { AppDescriptionCriteria } from './criterias/app-description-criteria';
import { AppSubtitleCriteria } from './criterias/app-subtitle-criteria';
import { AppKeywordsCriteria } from './criterias/app-keywords-criteria';
import { AppsSearchService } from './apps-search.service';

@Injectable({
  providedIn: 'root',
})
export class StartPageAppsSearchService {
  private searchCriteria: SearchCriteria[] = [
    new AppTitleCriteria(),
    new AppNameCriteria(),
    new AppDescriptionCriteria(),
    new AppSubtitleCriteria(),
    new AppKeywordsCriteria(),
  ];
  constructor(
    private platformService: PlatformService,
    private appsSearchService: AppsSearchService,
  ) {}

  searchStartPage(_query: string, _portalApps: AppInterface[]): SearchResults {
    const searchableApps = this.getSearchableApps(_portalApps);
    return this.mapToSearchResults(
      this.appsSearchService.search(
        searchableApps,
        _query,
        this.searchCriteria,
      ),
    );
  }

  private getSearchableApps(_apps: AppInterface[]): SearchableAppInterface[] {
    return _apps.filter((_app) => {
      if (this.platformService.isMobile()) {
        return _app.isActive && _app.supportsMobile;
      } else {
        return _app.isActive;
      }
    }) as SearchableAppInterface[];
  }

  private mapToSearchResults(_appsFound: AppInterface[]): SearchResults {
    const bookmarkResults: AppInterface[] = [];
    const startPageResults: AppInterface[] = [];
    const finderResults: AppInterface[] = [];
    let count = 0;
    for (const app of _appsFound) {
      if (app.isBookmark === true) {
        bookmarkResults.push(app);
      } else if (app.inDashboard) {
        startPageResults.push(app);
      } else {
        finderResults.push(app);
      }
      count++;
      if (count === 10) {
        break;
      }
    }
    return {
      finder: finderResults,
      startPage: startPageResults,
      bookmark: bookmarkResults,
    };
  }
}
