<main
  class="sn:ml-12"
  [ngClass]="{
    'main-container-open-sidenav':
      sideNavigationService.isSideNavOpen() &&
      !currentNavigationItemService.currentNavigationItem.hideSideNavigation,
  }"
>
  <ng-content></ng-content>
</main>
