import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FolderInterface } from '../../interfaces/folder.interface';

@Injectable({
  providedIn: 'root',
})
export class FolderService {
  private folderEndpoint = '/me/folders';
  private singleFolderUpdateEndpoint = '/singleupdate';

  constructor(private http: HttpClient) {}

  getFolders(): Observable<FolderInterface[]> {
    return this.http
      .get<FolderInterface[]>(environment.baseUrl + this.folderEndpoint)
      .pipe(take(1));
  }

  addFolder(folder: FolderInterface): Observable<FolderInterface> {
    return this.http
      .put<FolderInterface>(environment.baseUrl + this.folderEndpoint, folder)
      .pipe(take(1));
  }

  deleteFolder(folder: FolderInterface | number): Observable<FolderInterface> {
    const id = typeof folder === 'number' ? folder : folder.id;
    return this.http.delete<FolderInterface>(
      `${environment.baseUrl + this.folderEndpoint}/${id}`,
    );
  }

  updateFolders(folders: FolderInterface[]): Observable<FolderInterface[]> {
    return this.http.post<FolderInterface[]>(
      environment.baseUrl + this.folderEndpoint,
      folders,
    );
  }

  updateFolder(folder: FolderInterface): Observable<FolderInterface> {
    return this.http.post<FolderInterface>(
      `${environment.baseUrl + this.folderEndpoint + this.singleFolderUpdateEndpoint}`,
      folder,
    );
  }
}
