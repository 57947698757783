import { Injectable } from '@angular/core';
import { PermissionType } from '../enums/permission-type.enum';
import { User } from 'src/app/core/interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  checkPermission(user: User, permission: PermissionType): boolean {
    return user.permissions.includes(permission);
  }

  canUseAdminUI(user: User): boolean {
    return (
      this.checkPermission(user, PermissionType.UseAdminUI) &&
      this.checkPermission(user, PermissionType.CanModifyAllowedUrls)
    );
  }
}
