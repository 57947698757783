import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, switchMap, take, tap } from 'rxjs';
import { UserConfiguration } from '../../interfaces/userConfiguration.interface';

@Injectable({
  providedIn: 'root',
})
export class UserConfigurationService {
  private userConfigurationEndpoint = '/me/configuration';

  private userConfiguration: UserConfiguration | undefined;

  constructor(private http: HttpClient) {}

  public fetchUserConfiguration(): Observable<void> {
    return new Observable((obs) => {
      this.http
        .get<UserConfiguration>(
          environment.baseUrl + this.userConfigurationEndpoint,
        )
        .pipe(
          take(1),
          tap((_userConfig) => {
            this.userConfiguration = _userConfig;
          }),
        )
        .subscribe(() => {
          obs.complete();
        });
    });
  }

  public getOrDefault(key: string, defaultValue?: any): any | undefined {
    if (this.userConfiguration![key]) {
      return JSON.parse(this.userConfiguration![key]);
    }
    return defaultValue ?? undefined;
  }

  public set(key: string, value: any): Observable<void> {
    const valueBody = JSON.stringify(value);
    return this.http
      .put<UserConfiguration>(
        environment.baseUrl + this.userConfigurationEndpoint + '/' + key,
        valueBody,
      )
      .pipe(
        take(1),
        switchMap(() => this.fetchUserConfiguration()),
      );
  }
}
