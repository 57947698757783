<div *ngIf="toolTipStartPage || toolTipAppfinder; else defaultTooltip"
     class="tooltip"
     [ngClass]="{
       'start-page-tooltip': toolTipStartPage,
       'app-finder-tooltip': toolTipAppfinder
     }"
     [style.left.px]="left"
     [style.top.px]="top">
  <ng-container *ngTemplateOutlet="tooltipContent"/>
</div>

<ng-template #defaultTooltip>
  <div class="tooltip"
       [style.left.px]="left"
       [style.bottom.px]="bottom">
    <ng-container *ngTemplateOutlet="tooltipContent"/>
  </div>
</ng-template>

<ng-template #tooltipContent>
  @if (contentRef){
    <ng-container *ngTemplateOutlet="contentRef"/>
  } @else {
    {{tooltip}}
  }
</ng-template>
