<div class="navigation-container">
  @if (!currentNavigationItemService.currentNavigationItem.hideHeader) {
    <app-header></app-header>
  }
  <main-container>
    <ng-content></ng-content>
  </main-container>
  @if (!currentNavigationItemService.currentNavigationItem.hideFooter) {
    <app-footer></app-footer>
  }
</div>
<reset-startpage-popup></reset-startpage-popup>
