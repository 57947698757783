import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogEvent } from '../../interfaces/logEvent.interface';

@Injectable({
  providedIn: 'root',
})
export class LogEventService {
  private baseUrl: string = environment.baseUrl;
  private logEventEndpoint = `/log`;
  private logEventUrl = `${this.baseUrl}${this.logEventEndpoint}`;

  constructor(private http: HttpClient) {}

  public logServer(type: string, data: string = '', appId: string = '') {
    const userAgent: string = window.navigator.userAgent;
    const localcreated: string = new Date().toString();
    const title = document.title;
    const logEvent: LogEvent = {
      type,
      data,
      userAgent,
      appId,
      localcreated,
      title,
    };

    return this.http
      .post(this.logEventUrl, logEvent)
      .pipe(take(1))
      .subscribe(() => {});
  }
}
