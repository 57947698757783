import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  protected MOBILE_DEVICE_WIDTH = 768;

  public isMobile(): boolean {
    return this.platform.IOS || this.platform.ANDROID;
  }

  public isSmallMobileDevice(): boolean {
    const deviceWidth = window.innerWidth;
    return this.isMobile() && deviceWidth < this.MOBILE_DEVICE_WIDTH;
  }
}
