<li
  *ngIf="
    !navigationItemVisibilityService.isNavigationItemHidden(navigationItem)
  "
  [ngClass]="{
    'selected-item':
      isSelected() &&
      !navigationItemVisibilityService.isContainingVisibleChildren(
        navigationItem
      ),
    '-open': navigationItem.isOpenGroup,
  }"
  class="m-side-navigation__menuItem -open"
  role="none"
>
  <a
    (click)="navigationItemClickService.clickNavigationItem(navigationItem)"
    class="m-side-navigation__link"
    role="menuitem"
  >
    <i
      [ngClass]="navigationItem.icon"
      [title]="navigationItem.name"
      class="a-icon"
    ></i>
    <span class="m-side-navigation__label">
      {{ name }}
    </span>

    <i
      *ngIf="
        navigationItemVisibilityService.isContainingVisibleChildren(
          navigationItem
        )
      "
      [ngClass]="{
        'boschicon-bosch-ic-up': navigationItem.isOpenGroup,
        'boschicon-bosch-ic-down': !navigationItem.isOpenGroup,
      }"
      class="a-icon arrow boschicon-bosch-ic-down"
      title="up-down-buttons"
    >
    </i>
  </a>

  <ul
    *ngIf="navigationItem.isOpenGroup"
    class="m-side-navigation__menuSubitems"
    role="menu"
  >
    <side-navigation-action-item
      *ngFor="let sideNavActionItem of navigationItem.children"
      [childNavigationItem]="sideNavActionItem"
    >
    </side-navigation-action-item>
  </ul>
</li>
