<article class="app-icon-container" (click)="handleClick()">
  <a class="select-none">
    @defer (on viewport) {
    <img [ngClass]="{
          'big-image': displayType === DisplayType.BIG,
          'small-image': displayType === DisplayType.SMALL,
        }" class="select-none m-auto" [src]="app.appIconURL" alt="" />
    } @placeholder {
    <div [ngClass]="{
          'big-image': displayType === DisplayType.BIG,
          'small-image': displayType === DisplayType.SMALL,
        }"></div>
    }
  </a>
</article>