import { Injectable } from '@angular/core';
import { AppInterface } from '../../interfaces/app.interface';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AppFinderApiService } from './app-finder-api.service';

@Injectable({
  providedIn: 'root',
})
@Injectable({
  providedIn: 'root',
})
export class AppFinderDataService {
  allApps: BehaviorSubject<AppInterface[]> = new BehaviorSubject<
    AppInterface[]
  >([]);

  allApps$: Observable<AppInterface[]> = this.allApps.asObservable();

  constructor(private appFinderApiService: AppFinderApiService) {}

  fetchAllApps(): Observable<void> {
    return new Observable<void>((_obs) => {
      this.appFinderApiService
        .getApps()
        .pipe(take(1))
        .subscribe((_allApps) => {
          this.allApps.next(_allApps);
          _obs.next();
        });
    });
  }

  getAllApps(): Observable<AppInterface[]> {
    return this.allApps$;
  }
}
