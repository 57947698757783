import { Injectable } from '@angular/core';
import { ScrollService } from '../scroll-service/scroll.service';
import { UserConfigurationService } from '../userconfiguration/user-configuration.service';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WelcomeWizardSeenService {
  private visible: boolean = false;

  private currentActivePage: number = 1;

  constructor(
    private scrollService: ScrollService,
    protected userConfigurationService: UserConfigurationService,
  ) {}

  public fetchVisible(): Observable<void> {
    if (this.visible) {
      return EMPTY;
    }

    const KEY_WIZARD_SEEN = 'WIZARD_SEEN';

    const wizardSeen: boolean = this.userConfigurationService.getOrDefault(
      KEY_WIZARD_SEEN,
      false,
    ) as boolean;
    this.visible = !wizardSeen;

    if (this.visible) {
      this.showWizard();
      return this.userConfigurationService.set(KEY_WIZARD_SEEN, true);
    } else {
      this.hideWizard();
      return EMPTY;
    }
  }

  public getCurrentActivePage(): number {
    return this.currentActivePage;
  }

  public setCurrentActivePage(page: number): void {
    this.currentActivePage = page;
  }

  public showWizard() {
    this.currentActivePage = 1;
    this.visible = true;
    this.scrollService.disableScroll();
  }

  public hideWizard() {
    this.visible = false;
    this.currentActivePage = 1;
    this.scrollService.enableScroll();
  }

  public isVisible(): boolean {
    return this.visible;
  }
}
