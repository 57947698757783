import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FolderStateService {
  private currentFolderId: string | null = null;

  setCurrentFolderId(folderId: string) {
    this.currentFolderId = folderId;
  }

  getCurrentFolderId(): string | null {
    return this.currentFolderId;
  }
}
