import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationItem } from '../../../../interfaces/navigation-items';
import { ChildNavigationItem } from './child-navigation-item/child-navigation-item.component';
import { NavigationItemVisibilityService } from '../../../../services/navigation-service/navigation-item-visibility.service';
import { CurrentNavigationItemService } from '../../../../services/navigation-service/current-navigation-item.service';
import { NavigationItemClickService } from '../../../../services/navigation-service/navigation-item-click.service';

@Component({
  selector: 'side-navigation-item',
  standalone: true,
  imports: [CommonModule, ChildNavigationItem],
  templateUrl: './side-navigation-item.component.html',
  styleUrls: ['./side-navigation-item.component.scss'],
})
export class SideNavigationItemComponent {
  @Input({ required: true }) navigationItem!: NavigationItem;
  @Input({ required: true }) name!: string;

  constructor(
    protected navigationItemVisibilityService: NavigationItemVisibilityService,
    protected navigationItemClickService: NavigationItemClickService,
    protected currentNavigationItemService: CurrentNavigationItemService,
  ) {}

  isSelected(): boolean {
    return (
      this.currentNavigationItemService.currentNavigationItem?.name ===
        this.navigationItem.name ||
      this.currentNavigationItemService.currentNavigationItem?.parent?.name ===
        this.navigationItem.name
    );
  }
}
