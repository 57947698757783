import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppInterface } from '../../interfaces/app.interface';
import { Observable, take, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SwitchPosition } from '../../interfaces/switch-position.interface';
import { AppFinderDataService } from '../appfinder-service/app-finder-data.service';

@Injectable({
  providedIn: 'root',
})
export class AppInstanceService {
  private appInstanceCollectionEndpoint = '/me/appinstances-collection';

  private appInstanceEndpoint = '/me/appinstances';
  private switchPositionsEndpoint = '/switchpositions';

  constructor(
    private http: HttpClient,
    private appFinderDataService: AppFinderDataService,
  ) {}

  /**
   * This will take apps as parameter and send it to the backend.
   * The backend then creates an appInstance for the respective app.
   * It will then return list of a new app-object which includes an appInstanceId.
   * @param _apps
   * @param _searchFreeSpace
   */
  addAppInstances(
    _apps: AppInterface[],
    _searchFreeSpace = true,
  ): Observable<AppInterface[]> {
    return this.http
      .put<
        AppInterface[]
      >(environment.baseUrl + this.appInstanceCollectionEndpoint + '?searchFreeSpace=' + _searchFreeSpace, _apps)
      .pipe(
        take(1),
        tap((_saveAppInstance) => {
          for (const app of _saveAppInstance) {
            app.inDashboard = true;
          }
          this.appFinderDataService.fetchAllApps().subscribe();
        }),
      );
  }

  /**
   * This will take an app as parameter and send it to the backend.
   * The backend then creates and appInstance for the respective app.
   * It will then return a new app-object which includes an appInstanceId.
   * @param _app
   */
  addAppInstance(_app: AppInterface): Observable<AppInterface> {
    return this.http
      .put<AppInterface>(environment.baseUrl + this.appInstanceEndpoint, _app)
      .pipe(
        take(1),
        tap((_saveAppInstance) => {
          _app.inDashboard = true;
          _app.appInstanceId = _saveAppInstance.appInstanceId;
          this.appFinderDataService.fetchAllApps().subscribe();
        }),
      );
  }

  /** DELETE: delete the portal app from the server */
  deleteAppInstance(app: AppInterface): Observable<void> {
    return this.http
      .delete<void>(
        environment.baseUrl +
          this.appInstanceEndpoint +
          '/' +
          app.appInstanceId,
      )
      .pipe(take(1));
  }

  /** PUT: update the portal app on the server */
  updatePortalApp(_portalApp: AppInterface): Observable<AppInterface> {
    return this.http
      .post<AppInterface>(
        environment.baseUrl + this.appInstanceEndpoint,
        _portalApp,
      )
      .pipe(take(1));
  }

  updatePortalAppToFolder(_portalApp: AppInterface): Observable<AppInterface> {
    return this.http
      .post<AppInterface>(
        environment.baseUrl + this.appInstanceEndpoint,
        _portalApp,
      )
      .pipe(
        take(1),
        tap(() => {
          this.appFinderDataService.fetchAllApps().subscribe();
        }),
      );
  }
  getAppInstances(): Observable<AppInterface[]> {
    return this.http.get<AppInterface[]>(
      environment.baseUrl + this.appInstanceEndpoint,
    );
  }

  switchPositionOfApps(
    _app1: AppInterface,
    _app2: AppInterface,
  ): Observable<SwitchPosition> {
    const switchPosition: SwitchPosition = {
      firstAppInstance: _app1,
      secondAppInstance: _app2,
    };
    return this.http
      .post<SwitchPosition>(
        environment.baseUrl +
          this.appInstanceEndpoint +
          this.switchPositionsEndpoint,
        switchPosition,
      )
      .pipe(take(1));
  }
}
