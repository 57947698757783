<li
  *ngIf="
    !navigationItemVisibilityService.isNavigationItemHidden(childNavigationItem)
  "
  class="m-side-navigation__menuSubitem"
  role="none"
>
  <a
    (click)="
      navigationItemClickService.clickNavigationItem(childNavigationItem)
    "
    [routerLink]="[childNavigationItem.routerLink || router.url]"
    role="menuitem"
    class="m-side-navigation__link"
    tabindex="-1"
  >
    <span class="m-side-navigation__label">{{ childNavigationItem.name }}</span>
  </a>
</li>
