import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/*
 SidebarFilterStateService is a service that manages the state of the navigation sidebar temporarily, when opening and closing the AppFinderFilterBar.
 This is necessary, because of the animation of the NavigationBar. When opening the FilterBar, the NavigationBar must be closed temporarily. This service
 makes sure, that the NavigationBar has the same state as before opening the FilterBar.
*/

export class NavigationFilterStateService {

  menuBarOpen = signal(false);

  setMenuBarState(isOpen: boolean) {
    this.menuBarOpen.set(isOpen);
  }
}
