import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppInterface } from '../../interfaces/app.interface';

@Injectable({
  providedIn: 'root',
})
export class AppFinderApiService {
  private appsEndpoint = '/me/appFinder';
  constructor(private http: HttpClient) {}

  public getApps(_getActiveApps?: boolean): Observable<AppInterface[]> {
    let params = new HttpParams();
    if (_getActiveApps !== undefined) {
      params = params.set('getActiveApps', _getActiveApps.toString());
    }
    return this.http
      .get<AppInterface[]>(`${environment.baseUrl}${this.appsEndpoint}`, {
        params: params,
      })
      .pipe(take(1));
  }
}
