import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollPositions: Map<string, number> = new Map<string, number>();
  private scrolling: boolean = false;
  constructor() {}

  savePosition(key: string): void {
    this.scrollPositions.set(key, window.scrollY);
  }

  restorePosition(key: string): void {
    const position = this.scrollPositions.get(key) || 0;
    this.enableScroll();
    /* We need this to ensure, that scrolling happens after all other currently running actions are executed (f.e. component renderings, etc.).
       If the js call stack is currently executing other code, the setTimeout callback will wait in the event queue until the call stack is empty. */
    setTimeout(() => {
      window.scrollTo(0, position);
      this.forgetPosition(key);
    });
  }

  disableScroll(): void {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  }

  enableScroll(): void {
    document.body.style.overflow = '';
    document.documentElement.style.overflow = '';
  }

  setIsScrolling() {
    this.scrolling = true;
  }

  setFinishScroll() {
    this.scrolling = false;
  }

  isFinishScroll() {
    return this.scrolling === false;
  }

  forgetPosition(key: string): void {
    this.scrollPositions.delete(key);
  }

  resetScrollPosition(element?: HTMLElement): void {
    this.enableScroll();
    setTimeout(() => {
      if (element) {
        element.scrollTo(0, 0); 
      } else {
        window.scrollTo(0, 0); 
      }
    });
  }
  
}
