import { NameValueType } from '../../pages/app-finder-page/types/name-value.type';

export const CATEGORY = 'Category';

export const CATEGORY_NONE = { name: 'None', value: 'None' };
export const CATEGORIES: NameValueType[] = [
  CATEGORY_NONE,
  { name: 'Associate Services', value: 'Associate Services' },
  { name: 'Collaboration', value: 'Collaboration' },
  { name: 'Compliance', value: 'Compliance' },
  { name: 'HR', value: 'HR' },
  { name: 'IT Support', value: 'IT support' },
  { name: 'Logistics', value: 'Logistics' },
  { name: 'Manufacturing', value: 'Manufacturing' },
  { name: 'Data Management', value: 'Data Management' },
  { name: 'Purchasing', value: 'Purchasing' },
  {
    name: 'Quality & Maintenance',
    value: 'Quality & Maintenance',
  },
  { name: 'R&D, Engineering', value: 'R&D Engineering' },
  { name: 'Sales & Distribution', value: 'Sales & Distribution' },
  { name: 'Finance Accounting', value: 'Finance Accounting' },
];
