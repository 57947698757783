import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() tooltip: string = '';
  @Input() toolTipStartPage = false;
  @Input() toolTipAppfinder = false;
  @Input() contentRef: TemplateRef<unknown> | undefined;

  left: number = 0;
  bottom: number = 0;
  right: number = 0;
  top: number = 0;
}
