<div *ngIf="isUserFromInternet" class="container">
  <div class="navbar__actions">
    <button class="btn-navbar-client">
      <span class="btn-client">Logged in from Internet Client</span>
      <span
        class="btn-icon"
        [ngClass]="{ 'btn-icon-start-page': currentNavigationOnStartPage() }"
        style="background-image: url(assets/IDE_KeyVisual_minimal.png)"
      ></span>
    </button>
  </div>
</div>
