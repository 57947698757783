import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { AppInterface } from '../../interfaces/app.interface';
import { KeyValuePair } from '../../interfaces/key-value-pair.interface';

@Injectable({
  providedIn: 'root',
})
export class AppDetailsService {
  baseUrl = environment.baseUrl;
  appDetailsUrl = `${this.baseUrl}/apps`;

  appHeadersUrl = `${this.baseUrl}/apps`;

  appDetails!: BehaviorSubject<AppInterface>;
  constructor(private http: HttpClient) {}

  getAppDetails(id: number): Observable<AppInterface> {
    return this.http
      .get<AppInterface>(`${this.appDetailsUrl}/${id}`)
      .pipe(take(1));
  }
}
