<div
  [ngClass]="{
    'sidenav-open-margins':
      sideNavigationService.isSideNavOpen() &&
      !currentNavigationItemService.currentNavigationItem.hideSideNavigation,
    'sidenav-closed-margins': !sideNavigationService.isSideNavOpen(),
    'no-margin-left':
      currentNavigationItemService.currentNavigationItem.hideSideNavigation ||
      platformService.isMobile(),
  }"
  class="footer"
>
  <footer class="o-footer -minimal">
    <hr class="a-divider" />
    <div class="e-container">
      <div
        [ngClass]="{
          'o-footer__bottom_sideNavClosed':
            !sideNavigationService.isSideNavOpen(),
        }"
        class="o-footer__bottom"
      >
        <ul class="o-footer__links">
          <li>
            <div class="a-link a-link--integrated">
              <a
                role="button"
                aria-haspopup="true"
                aria-label="Show privacy settings"
                onclick="showDockPrivacySettings()"
              >
                <span>Privacy Settings</span>
              </a>
            </div>
          </li>
          <li>
            <div class="a-link a-link--integrated">
              <a
                href="https://bgn.bosch.com/FIRSTspiritWeb/permlink/wcms_corpfunc_-transparency_1-EN"
                target="_blank"
              ><span>Data protection policy</span></a
              >
            </div>
          </li>
          <li>
            <div>
              <span>{{ appInfoService.version }}</span>
            </div>
          </li>
        </ul>
        <hr class="a-divider" />
        <div class="o-footer__copyright">
          <i class="a-icon boschicon-bosch-ic-copyright-frame"></i>
          {{ year }} Robert Bosch GmbH, all rights reserved
        </div>
      </div>
    </div>
  </footer>
</div>
