import { Injectable } from '@angular/core';
import { User } from '../../interfaces/user.interface';
import { AppUser } from '../../interfaces/app-details-interfaces/app-user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /*
    In typescript when you define a new variable of type T; you can tell the typescript compiler that
    the object will be initialized before usage (at another place) with the following syntax:
    private objectName!: Object;
    The "!" behind the object name tells typescript, that the object will be initialized before usage.
    This user is being set in app_initializer before the boostrap of the app.
   */
  private user!: User;

  getUser(): User {
    return this.user;
  }

  setUser(_user: User) {
    this.user = _user;
  }

  getUserAsAppUser(): AppUser {
    return {
      displayName: this.user.displayName,
      id: this.user.id,
      userPrincipalName: this.user.ntId,
      mail: this.user.emailAddress,
    };
  }
}
