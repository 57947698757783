import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToastMessageInterface } from 'src/app/core/interfaces/toast-message.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  message = new BehaviorSubject<ToastMessageInterface>({
    primaryMessage: '',
    secondMessage: '',
  });
  openToastMessage = new BehaviorSubject<boolean>(false);
  message$ = this.message.asObservable();
  openToastMessage$ = this.openToastMessage.asObservable();

  constructor() {}

  openToastDialog(message: ToastMessageInterface): void {
    this.message.next({
      primaryMessage: message.primaryMessage,
      secondMessage: message.secondMessage,
    });
    this.openToastMessage.next(true);
  }
}
