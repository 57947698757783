import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ResetFiltersService {
    private resetSearchActionSource = new Subject<void>();
    private resetFiltersActionSource = new Subject<void>();

    resetSearchAction$ = this.resetSearchActionSource.asObservable();
    resetFiltersAction$ = this.resetFiltersActionSource.asObservable();

    triggerResetSearchAction() {
      this.resetSearchActionSource.next();
    }

    triggerResetFilterAction() {
      this.resetFiltersActionSource.next();
    }
}
