import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppInterface,
  ClickOnAppBehavior,
  DisplayType,
} from '../../../interfaces/app.interface';
import { AppManager } from '../../../interfaces/appManager.interface';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-icon.component.html',
  styleUrls: ['./app-icon.component.scss'],
})
export class AppIconComponent {
  @Input({ required: true }) app!: AppInterface | AppManager;
  @Input() displayType: DisplayType = DisplayType.SMALL;
  @Output() appIconClicked: EventEmitter<void> = new EventEmitter<void>();
  protected readonly DisplayType = DisplayType;
  constructor() {}

  protected handleClick() {
    this.appIconClicked.emit();
  }

  protected readonly ClickOnAppBehavior = ClickOnAppBehavior;
}
