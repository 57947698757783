import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '../../interfaces/navigation-items';
import { PlatformService } from '../platform-service/platform.service';
import { CurrentNavigationItemService } from './current-navigation-item.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationItemVisibilityService {
  constructor(
    private router: Router,
    private platformService: PlatformService,
    private currentNavigationItemService: CurrentNavigationItemService,
  ) {}

  public isNavigationItemHidden(_navigationItem: NavigationItem): boolean {
    return (
      (_navigationItem.hideOnOtherNavigationItems?.includes(
        this.currentNavigationItemService.currentNavigationItem,
      ) ??
        false) ||
      ((_navigationItem.hideOnMobile ?? false) &&
        this.platformService.isMobile())
    );
  }

  public isContainingVisibleChildren(_navigationItem: NavigationItem): boolean {
    if (!this.platformService.isMobile()) {
      return (_navigationItem.children?.length ?? 0) > 0;
    }
    return (
      (_navigationItem.children?.filter((_navItem) => !_navItem.hideOnMobile)
        .length ?? 0) > 0
    );
  }
}
