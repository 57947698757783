<div
  class="search-results-container"
  [ngClass]="
    showSearchResult() || (searchResultsService.openSearch$ | async)
      ? 'open'
      : 'close'
  "
>
  <div class="start-page-result-container">
    <span class="start-page-result-container-title-page">Start Page</span>
    <ul>
      <li
        *ngFor="let app of filteredApps().startPage"
        class="application-container"
      >
        <app
          (click)="logAppSearchResult(app)"
          [clickOnAppBehavior]="ClickOnAppBehavior.OPEN_APP_BY_URL"
          [app]="app"
          [showAddRemoveButton]="false"
        ></app>
      </li>
    </ul>
    <ul>
      <li
        *ngFor="let app of filteredApps().bookmark"
        class="application-container"
      >
        <bookmark (click)="logBookmarkResult(app)" [bookmark]="app"></bookmark>
      </li>
    </ul>
    <span
      *ngIf="
        !filteredApps().startPage.length && !filteredApps().bookmark.length
      "
      class="start-page-result-container-no-app-found"
      >No apps found.</span
    >
  </div>
  <div class="search-result-line"></div>
  <div class="apps-finder-result-container">
    <span class="apps-finder-result-container-title-page"> App Finder</span>
    <ul>
      <li
        *ngFor="let app of filteredApps().finder"
        class="application-container"
      >
        <app
          (click)="logAppSearchResult(app)"
          [app]="app"
          [showAddRemoveButton]="false"
        ></app>
      </li>
    </ul>
    <span
      class="apps-finder-result-container-no-app-found"
      *ngIf="!filteredApps().finder.length"
      >No apps found.</span
    >
  </div>

  <div
    *ngIf="showSearchResult() || (searchResultsService.openSearch$ | async)"
    class="close-button-container"
  >
    <div class="close-button" (click)="closeSearchResults()">close</div>
  </div>
</div>
