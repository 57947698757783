import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DragDropStateService {
  public dialogOpenSource = new BehaviorSubject<boolean>(false);
  dialogOpen$ = this.dialogOpenSource.asObservable();

  constructor() {}

  setDialogOpen(isOpen: boolean): void {
    this.dialogOpenSource.next(isOpen);
  }
}
