@if (isMinimal) {
  <search-bar
    [query]="queryFormControl"
    [isCollapsible]="true"
    (queryChanged)="updateSearchQuery($event)"
    (focusChanged)="focusChangeEvent($event)"
    placeholder="Search in NG Portal"
  />
} @else {
  <search-bar
    [query]="queryFormControl"
    [isCollapsible]="false"
    [isGrayBackground]="false"
    [isLightGrayBackground]="true"
    (queryChanged)="updateSearchQuery($event)"
    (focusChanged)="focusChangeEvent($event)"
    placeholder="Search in NG Portal"
  />
}

<search-result
  [openSearchResult]="openSearchResult()"
  [filteredApps]="filteredApps"
/>



