import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AppDetailsService } from '../services/app-details-service/app-details.service';
import { BehaviorSubject, map } from 'rxjs';
import { UserService } from '../services/user-service/user.service';
import { AppInterface } from '../interfaces/app.interface';

export const editAppGuard: CanActivateFn = (_route) => {
  const checkIfUserIsAppOwner = checkIfUserIsAppOwnerProviderFn();
  const appDetailsService = inject(AppDetailsService);
  return appDetailsService.getAppDetails(_route.params['id']).pipe(
    map((_appDetails) => {
      if (checkIfUserIsAppOwner(_appDetails)) {
        appDetailsService.appDetails = new BehaviorSubject(_appDetails);
        return true;
      }
      return false;
    }),
  );
};

function checkIfUserIsAppOwnerProviderFn(): (_app: AppInterface) => boolean {
  const userService = inject(UserService);
  return (_app: AppInterface) => {
    return [_app.appCreator, ..._app.appEditors!].some((_user) => {
      return _user?.id !== userService.getUser().id;
    });
  };
}
