import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonTypeEnum } from '../../enums/buttonType.enum';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-button.component.html',
  styleUrls: ['./app-button.component.scss'],
})
export class AppButtonComponent {
  @Input({ required: true }) buttonType!: ButtonTypeEnum;
  @Input({ required: true }) buttonLabel!: string;
  @Input() isIntegratorButton: boolean = false;
  @Input() isDisabled: boolean = false;

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
