<section class="app-container">
  <div class="left-part" (click)="openAppURL()">
    <article>
      <div class="bookmark-icon">
        <svg
          width="57"
          height="57"
          viewBox="0 0 63 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3849_7423)">
            <path
              d="M49.3763 59.1666C49.1318 59.1666 48.9281 59.1259 48.6837 59.0036L31.4504 51.1814L14.2171 59.0036C13.6874 59.2481 13.0356 59.1666 12.5467 58.7999C12.0578 58.4333 11.8134 57.8221 11.9356 57.2518L15.0726 38.5111L0.976311 24.4555C0.52818 24.0074 0.405963 23.3963 0.568919 22.8259C0.772615 22.2555 1.26151 21.8074 1.87262 21.7259L20.6541 18.5888L30.0652 1.35552C30.3504 0.825913 30.88 0.5 31.4911 0.5C32.1022 0.5 32.6319 0.825913 32.917 1.35555L42.3281 18.5889L61.1096 21.7259C61.7207 21.8074 62.2096 22.2555 62.4133 22.8259C62.617 23.3963 62.454 24.0481 62.0059 24.4555L47.9503 38.5111L51.0874 57.2518C51.1689 57.8629 50.9652 58.4333 50.4763 58.7999C50.0689 59.0444 49.7429 59.1666 49.3763 59.1666ZM31.4504 47.7592C31.6948 47.7592 31.8985 47.8 32.143 47.9222L47.2985 54.8073L44.5281 38.2666C44.4467 37.737 44.6096 37.2074 44.9763 36.8407L57.4022 24.4148L40.9837 21.6852C40.4948 21.6037 40.0466 21.2778 39.8022 20.8704L31.4504 5.55186L23.0985 20.8296C22.8541 21.2778 22.4467 21.563 21.917 21.6444L5.49854 24.3741L17.9244 36.8C18.2911 37.1666 18.4541 37.6963 18.3726 38.2259L15.6022 54.7666L30.7578 47.8814C31.0022 47.8 31.2059 47.7592 31.4504 47.7592Z"
              [attr.fill]="bookmark.color"
            />
          </g>
          <defs>
            <clipPath id="clip0_3849_7423">
              <rect
                width="62"
                height="62"
                fill="white"
                transform="translate(0.5 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </article>
  </div>
  <div class="right-part">
    <div class="label">
      <div>
        <a (click)="openAppURL()" class="title line-clamp-2">
          {{ bookmark.appName }}
        </a>
      </div>
    </div>
  </div>
</section>
