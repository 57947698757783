<dialog-popup
  #dialog
  [title]="'Reset Start Page'"
  [activePrimaryButton]="true"
  [activeSecondaryButton]="true"
  [isAutoHeight]="true"
  primaryButtonName="Cancel"
  (primaryButtonClicked)="dialog.closeDialog()"
  secondaryButtonName="Reset"
  (secondaryButtonClicked)="doReset()"
  [displayWarningIcon]="true"
>
  <p>
    Are you sure you want to reset your Start Page? <br>
    By resetting it, your bookmarks and folders will be deleted.
  </p>

</dialog-popup>
