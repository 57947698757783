import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchResultsService {
  shouldOpenSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);

  openSearch$: Observable<boolean> = this.shouldOpenSearch.asObservable();
}
