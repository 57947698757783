<form
  [ngClass]="{
    collapsed: isCollapsible,
    'full-size': showInput,
    'gray-background': isGrayBackground,
    'no-background-with-border': noBackgroundWithBorder,
  }"
>
  <input
    #inputElement
    [formControl]="query"
    [value]="query.value"
    type="search"
    (focusout)="focusout()"
    [ngClass]="{
      'full-size': showInput,
      show: !isCollapsible || showInput,
      'light-gray-background': isLightGrayBackground,
    }"
    [placeholder]="placeholder"
    (focus)="focusIn()"
  />
  <button type="submit" (click)="click()">
    <i class="boschicon-bosch-ic-search" title="Search Icon"></i>
  </button>
</form>
